<template>
  <div>
    <img src="../assets/sparkly-leaves.svg" alt="leaves" class="background" />
    <div class='background-image-container'>
      <img src="../assets/stand-for-ukraine.svg" alt="leaves" class="poster" />
      <div class='content-center'>
        <h1>{{ $t('DiscoverYourType') }}</h1>
        <label>{{ $t('YourEmail') }}</label>
        <form>
          <div>
            <InputFieldWithLabel
              id='email'
              type='email'
              :error='validationError'
              :required="true"
              placeholder='john.doe@mail.com'
              @value="setUserEmail"
            />
            <router-link
              :to="`/${$i18n.locale}/privacy`"
              class="disclaimer link link--subtle"
            >
              {{ $t('WhyEmail') }}
            </router-link>
          </div>
          <MyTypeButton
            type='submit'
            value='Submit'
            :text='$t("TakeTest")'
            class="margin-top"
            :onClick='redirectToTest'
          />
        </form>
        <p class="disclaimer">
          <span>💔</span>{{ $t('Test.Disclamer') }} 🙂<br />
          <strong>{{ $t('Test.Disclamer.After') }}</strong>
        </p>
      </div>
    </div>
    <div class='grid cols-2'>
      <div class='bg-red col col__text'>
        <h2>{{ $t('MeetSocionics') }}</h2>
        <br />
        <p>{{ $t('MeetSocionics.Text') }}</p>
      </div>
      <div class='bg-blue col col__img'>
        <img src='/brain-with-loop.svg' alt="brain with loop" />
      </div>
    </div>

    <div class="app-banner">
      <div class="grid cols-2 why">
        <div>
          <img src="../assets/logo-with-label.svg" alt="meetch logo">
          <br />
          <h2>{{ $t('WhyMeetch') }}</h2>
          <span>{{ $t('WhyMeetch.Subtitle') }}</span>
          <ul>
            <li>
              <img src="../assets/search.svg" class="icon" alt="search" />
              <p>{{ $t('WhyMeetch.Reason1') }}</p>
            </li>
            <li>
              <img src="../assets/heart.svg" class="icon" alt="heart" />
              <p>{{ $t('WhyMeetch.Reason2') }}</p>
            </li>
            <li>
              <img src="../assets/badge.svg" class="icon" alt="verifiaction" />
              <p>{{ $t('WhyMeetch.Reason3') }}</p>
            </li>
          </ul>
          <span>{{ $t('GetMeetch') }}</span>
          <div class="store-links store-links--home">
            <a href="https://apps.apple.com/fi/app/meetch-meet-your-match/id1553665471" target="blank">
              <img src="/apple-store-badge.svg" height="50" alt="apple-store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=app.meetch.meetch" target="blank">
              <img src="/google-play-badge.png" height="50" alt="google-play" />
            </a>
          </div>
        </div>
        <div class="app-mockup">
          <img class="app-banner__phone" src="../assets/app-mockup.svg" alt="dating app">
        </div>
      </div>
      <div class="how">
        <h2>{{ $t('HowItWorks') }}</h2>
        <div class="grid cols-4">
          <div class="how__step">
            <img src="../assets/screenshot-onboarding.svg" alt="onboarding" />
            <p>{{ $t('HowItWorks.Step1') }}</p>
          </div>
          <div class="how__step">
            <img src="../assets/screenshot-welcome.svg" alt="welcome" />
            <p>{{ $t('HowItWorks.Step2') }}</p>
          </div>
          <div class="how__step">
            <img src="../assets/screenshot-feed.svg" alt="feed" />
            <p>{{ $t('HowItWorks.Step3') }}</p>
          </div>
          <div class="how__step">
            <img src="../assets/screenshot-matches.svg" alt="matches" />
            <p>{{ $t('HowItWorks.Step4') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div id='about'>
      <h2>{{ $t('SocioniscVsMBTI') }}</h2>
      <div class='grid cols-2'>
        <div class='col col__text'>
          <h3>{{ $t('Goal') }}</h3>
          <p>{{ $t('Goal.Description') }}</p>
          <p v-if="$i18n.locale == 'en'">
            MBTI studies only the 16 types as individuals with their pros and cons.
          </p>
          <h3>{{ $t('SocionicsType.NeverChanges.Title') }}</h3>
          <p>
            {{ $t('SocionicsType.NeverChanges.Description') }}
          </p>
          <p v-if="$i18n.locale == 'en'">
            MBTI’s personality type can be
            identified by taking the test only.
            Often enough the test result is the person’s own vision of himself or what he
            would like to look like. The result is correct only if the person knows themselves
            very well. Therefore, MBTI type can change during the lifetime,
            for example when you dramatically change your surroundings or something
            significant happens in your life.
          </p>
        </div>
        <div class='col col__img' :style="{ paddingTop: 0 }">
          <img src='/puzzle.svg' alt="16 personalities puzzle" />
        </div>
      </div>
      <div class='grid cols-2'>
        <div class='col col__img col__img--reverse' :style="{ paddingTop: 0 }">
          <img src='/electric-brains.svg' alt="brain activity" />
        </div>
        <div class='col col__text'>
          <h3>{{ $t('IntertypeRelationships') }}</h3>
          <p>
            {{ $t('Intertype.Relationships.Description') }}
          </p>
          <p v-if="$i18n.locale == 'en'">
            MBTI suggests that intertype relationships are symmetrical and
            you can achieve the same outcomes interacting with any other type.
          </p>
        </div>
      </div>
      <div class='grid cols-2'>
        <div class="col col__text">
          <h3>{{ $t('Socionics.Reasons') }}</h3>
          <ul>
            <li>{{ $t('Socionics.Reasons.1') }}</li>
            <li>{{ $t('Socionics.Reasons.2') }}</li>
            <li>{{ $t('Socionics.Reasons.3') }}</li>
            <li>{{ $t('Socionics.Reasons.4') }}</li>
          </ul>
        </div>
        <div class='col center'>
          <img src='/logo.svg' alt="logo" class="logo" />
        </div>
      </div>
      <div v-if="$i18n.locale == 'en'">
        <div class="col col__text">
          <h3>Where to learn more about Socionics?</h3>
          <p>Check out this book
            <a
              class="link--red"
              target="blank"
              href="https://www.amazon.com/Psychological-Types-Different-Portraits-Socionics/dp/1696480094/ref=sr_1_1?dchild=1&keywords=psychological+types+gulenko&qid=1607190325&sr=8-1">
              "Psychological Types: Why Are People So Different?"
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InputFieldWithLabel from '../components/InputFieldWithLabel.vue';
import MyTypeButton from '../components/MyTypeButton.vue';

export default {
  name: 'Home',
  components: {
    InputFieldWithLabel,
    MyTypeButton,
  },
  data() {
    return {
      userEmail: this.$store.getters.userEmail || '',
      validationError: null,
    };
  },
  computed: {
    ...mapGetters(['personalityTypes']),
    emailIsValid() {
      const re = /\S+@\S+\.\S+/;
      return re.test(this.userEmail);
    },
    isMobile() {
      if (window.innerWidth <= 820) return true;
      return false;
    },
  },
  methods: {
    ...mapActions(['getTypes']),
    setUserEmail(email) {
      this.userEmail = email;
    },
    redirectToTest($event) {
      if (this.emailIsValid) {
        this.$router.push({ name: 'Questionnaire' });
        if (!this.personalityTypes.length) this.getTypes();
        this.$store.commit('setUserEmail', this.userEmail);
      } else {
        $event.preventDefault();
        this.validationError = this.$t('Validation.Error.Email');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles/index' as *;
@use '@/styles/breakpoints' as *;

.background {
  position: absolute;
  display: none; // don't show on mobile
  // transform: rotate(-20deg);
  z-index: -1;
  top: 0;
  left: 0;

  @include breakpoint(medium) {
    display: inline;
  }
}

.background-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 5em;

  .poster {
    position: absolute;
    bottom: -2em;
    right: 0;
    z-index: -1;
    display: none;

    @include breakpoint(large) {
      display: block;
    }
  }

  .content-center {
    text-align: center;
    width: 100%;
    padding-top: 10em;

    @include breakpoint(medium) {
      margin: auto;
      padding-top: 12em;
    }

    @include breakpoint(large) {
      margin: auto;
      padding-top: 10em;
    }

    h1 {
      margin: auto;
      margin-bottom: 1em;
      font-size: 2.5em;
      font-weight: bold;

      @include breakpoint(large) {
        margin-top: 0;
        margin-bottom: 0.7em;
        font-size: 4em;
      }
    }

    p {
      margin: auto;
      padding: 1.5em 1em;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 0.5em;
      margin: 2em;
      margin-bottom: 0;

      @include breakpoint(large) {
        width: 500px;
        margin: 2em auto;
      }

      span {
        font-size: 18px;
      }
    }

    label {
      font-size: 18px;
    }

    form {
      margin-top: 1em;
    }
  }
}

.bg-red {
  padding: 10%;
}

.app-banner {
  justify-content: space-around;
  background-color: #E9F1FF;
  width: 100%;
  min-height: 300px;
  padding-bottom: 10em;
  overflow: hidden;
  text-align: left;

  @include breakpoint(large) {
    background-position-x: center;
  }

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 45px;
    line-height: 1em;
    @include breakpoint(large) {
      font-size: 55px;
    }
  }

  .why {
    margin: auto;
    background-image: url('../assets/app-banner-bg.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    background-position-y: bottom;
    padding: 10% 10% 30% 10%;

    @include breakpoint(medium) {
      padding: 10% 10% 35% 10%;
    }

    @include breakpoint(large) {
      padding-bottom: 20%;
    }

    span {
      font-size: 30px;
      font-weight: bold;
    }
    ul {
      margin: 3em 0;
      padding: 0;
      font-size: 16px;

      li {
        display: flex;
        margin-bottom: 1em;

        p {
          margin: 0;
        }

        .icon {
          height: 30px;
          margin-right: 2em;
          margin-top: 0.5em;
        }
      }
    }
  }

  .how {
    padding: 0 10%;

    &__step {
      text-align: center;
      padding-top: 2em;
    }
  }

  .app-mockup {
    margin: auto;
  }

  &__phone {
    position: relative;
    margin: auto;
    bottom: -20px;
    display: none;

    @include breakpoint(large) {
      display: inline;
    }
  }
}

#about {
  max-width: 1440px;
  margin: auto;
  padding-top: 3em;

  h2 {
    padding: 0 1em;
    line-height: 1.5em;
    font-size: 40px;
  }

  h3 {
    font-size: 30px;
  }
}

.logo {
  height: 100px;

  @include breakpoint(large) {
    height: 200px;
  }
}
</style>
